<template>
  <v-bottom-navigation flat app color="primary">
    <v-btn
      v-for="(nav, index) in navigationBar"
      :key="index"
      :to="nav.link"
      plain
    >
      <span>{{ nav.title }}</span>

      <v-icon>{{ nav.icon }}</v-icon>
    </v-btn>
    <v-btn href="tel:+91-9923478787" plain>
      <span>Call</span>
      <v-icon>fas fa-phone-alt</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {
  data() {
    return {
      navigationBar: [
        { title: "Home", icon: "fas fa-home", link: "/" },
        {
          title: "About us",
          //icon: "fas fa-house-laptop",
          icon: "fas fa-laptop-house",
          link: "/hmp/about",
        },
        //{ title: "Add", icon: "fas fa-plus-circle", link: "/user/addproperty" },
        {
          title: "Services",
          icon: "fas fa-people-carry",
          link: "/hmp/services",
        },
        { title: "Contact", icon: "fas fa-mobile-alt", link: "/hmp/contactus" },
      ],
    };
  },
};
</script>
