<template>
  <div>
    <HomeAppBar />
    <v-container>
      <v-row class="d-flex justify-center align-center flex-column mt-1">
        <v-col cols="11" class="py-4">
          <SearchProperty />
        </v-col>
      </v-row>
    </v-container>

    <v-card class="mx-4 mt-4 pa-4" outlined rounded="xl">
      <v-row class="d-flex justify-center flex-row">
        <v-col cols="6" class="mt-2">
          <div class="caption blue-grey--text lighten-4--text">
            Perfect place to find your
          </div>
          <div class="text-h4 font-weight-bold blue--text">
            Best Property
          </div>
        </v-col>
        <v-col cols="6" class="">
          <v-img
            alt="House_searching"
            src="../assets/House_searching.png"
            transition="scale-transition"
          />
        </v-col>
      </v-row>
    </v-card>
   
      <v-container>
        <v-row class="d-flex justify-center align-center flex-column">          
          <v-col cols="12" class="d-flex justify-center align-center">
            <PropertyTypeSlide />           
          </v-col>
          <v-col cols="12" class="d-flex justify-center align-center">          
            <iframe
            width="390"
            height="200"
            src="https://www.youtube.com/embed/Q0evdba8WLk?si=2O120MV4Z796fdeF"
            title="100 Miles Realtors"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="rounded-lg"
          />
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            
          </v-col>          
        </v-row>
         <v-row class="my-2 white d-flex justify-center align-center">
          <v-col class="ml-3 grey--text text--darken-3 font-weight-bold text-subtitle-1">
            Featured Property
          </v-col>
          <v-card
            class="d-xl-none mb-3 px-2 py-1 rounded-xl"
            elevation="0"
            max-height="400"
            width="380"
          >
            <v-slide-group class="d-flex justify-center rounded-xl center-active" >
              <v-slide-item class="mx-2 my-2 " v-for="property in favouriteProperty"
              :key="property.id" v-slot="{toggle }">
              
              <FavouriteProperty
              :favProperty="property"
              @click="toggle"
             />
              </v-slide-item>
            </v-slide-group>
            </v-card>
            </v-row>
      </v-container>
      <v-container>
        <v-row class="d-flex justify-center">          
          <v-col>
            <v-card outlined elevation="0" rounded="lg" class="pt-5">
              <span class="ml-3 grey--text text--darken-3 font-weight-bold text-subtitle-1">
                All Properties
              </span>
              <DisplayProperty
              v-for="property in allProperty"
              :key="property.id"
              :property="property"
            />
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="allProperty != null" class="justify-center align-center mb-3">
          <Pagination store="property" collection="allProperty" />
        </v-row>
        <ShowUserReview />
      </v-container>
      <BottomNavigationBar />
    </div>

</template>
<script>
import HomeAppBar from "@/components/HomeAppBar.vue";
import SearchProperty from "@/components/SearchProperty.vue";
import FavouriteProperty from "@/components/FavouriteProperty.vue";
import DisplayProperty from "@/components/DisplayProperty.vue";
import Pagination from "@/components/Pagination.vue";
import ShowUserReview from "@/components/ShowUserReview.vue";
import BottomNavigationBar from "@/components/BottomNavigationBar.vue";
import { mapState, mapActions } from "vuex";
import PropertyTypeSlide from "@/components/PropertyTypeSlide.vue";
export default {
  name: "Home",
  components: {
    HomeAppBar,
    SearchProperty,
    FavouriteProperty,
    Pagination,
    DisplayProperty,
    ShowUserReview,
    BottomNavigationBar,
    PropertyTypeSlide,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      allProperty: (state) => state.property.allProperty.data,
      favouriteProperty: (state) => state.property.favouriteProperty.favourite_property,
    }),
  },
  mounted() {
    this.showProperty(0);
    this.getfavouriteProperty();
  },
  methods: {
    ...mapActions(["showProperty","getfavouriteProperty"]),
  },
};
</script>
<style scoped>
.search-bg {
  height: 100px;
  padding: 2px;
  background: #fafafa;
  /*background: linear-gradient(180deg, #a0b5eb 0%, #c9f0e4 100%);*/
  /* background-image: linear-gradient(
    180deg,
    rgba((23, 234, 217, 0.9)) 0%,
    rgba(96, 120, 234, 0.8) 100%
  );  */
  border-radius: 7px;
}

/* .search-box-bg{
  background:#fff;
   position: relative;
   height: 100%;
  width: 100%;
   border-radius: 5px;
} */
.border-home {
  border-color: black !important;
  border-width: 5px 5px 5px 5px;
}
</style>
