<template>

          <v-card
            class="rounded-xl"
            light
            outlined
            elevation="0"
            height="275"
            width="275"
            :to="{
              name: 'property',
              params: { id: favProperty.basic_property_id },
            }"           
          >
            <v-img
              :src="
                imgURL +
                '/' +
                favProperty.user_id +
                '/' +
                favProperty.basic_property_id +
                '/' +
                favProperty.image_name
              " 
              width="auto"            
              height="150"              
            />
            <v-card-title>
              <div class="grey--text text--darken-3 text-body-1 font-weight-bold">
                <span v-if="favProperty.bedroom"> {{ favProperty.bedroom }} BHK </span>
                &nbsp;{{ favProperty.propertytype.property_name }} for
                {{ favProperty.pfor }}
              </div>
            </v-card-title>
            <v-card-text class="text-caption                
                  bluey-grey--text
                  text--darken-3
                  font-weight-bold">
              <v-icon
                left
                x-small
                color="
                  blue-grey accent-4"
              >
                fas fa-map-marker-alt</v-icon
              >
              {{ favProperty.locality }} • {{ favProperty.city }}
            
            <div >
              <v-icon
                left
                small
                color="
                bluey-grey accent-4"
              >
                fas fa-rupee-sign
              </v-icon>
              <span>{{ this.editedPrice }}</span>
            </div>
          </v-card-text>
          </v-card>     
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Featured-Property",
  props: ["favProperty"],
  data() {
    return {
      editedPrice: "",
      stringVal: "",
      amt: "",
      imgURL: "https://100milesrealtors.com/routes/storage/images",
    };
  },

  mounted() {
    
    this.printValue();
  },
  methods: {
    ...mapActions(["viewPropertyDetails"]),

    printValue() {
      let amt = this.favProperty.price;
      let count = amt.toString().length;
      console.log("count is :", count);
      switch (count) {
        case 6:
          this.stringVal = amt.toString().substr(0, 3);
          this.editedPrice =
            this.stringVal.toString().substr(0, 1) +
            "." +
            this.stringVal
              .toString()
              .substr(1, this.stringVal.toString().length) +
            " Lac";
          break;
        case 7:
          this.stringVal = amt.toString().substr(0, 4);
          this.editedPrice =
            this.stringVal.toString().substr(0, 2) +
            "." +
            this.stringVal
              .toString()
              .substr(2, this.stringVal.toString().length) +
            " Lac";
          break;
        case 8:
          this.stringVal = amt.toString().substr(0, 3);
          this.editedPrice =
            this.stringVal.toString().substr(0, 1) +
            "." +
            this.stringVal
              .toString()
              .substr(1, this.stringVal.toString().length) +
            " Crore";
          break;
        case 9:
          this.stringVal = amt.toString().substr(0, 4);
          this.editedPrice =
            this.stringVal.toString().substr(0, 2) +
            "." +
            this.stringVal
              .toString()
              .substr(2, this.stringVal.toString().length) +
            " crore";
          break;
      }
    },
  },
};
</script>

<style scoped></style>
