<template>
  <v-app-bar color="white" flat app height="72">
    <div class="d-flex align-center">
      <v-img
        alt="100 Miles Logo"
        class="mr-2 shrink"
        contain
        src="../assets/100logo.png"
        transition="scale-transition"
        max-height="48"
        max-width="48"
        href="/"
      />
      <v-app-bar-title class="blue--text font-weight-bold text-body-1">
        100 MILES REALTORS
      </v-app-bar-title>
    </div>
    <v-spacer></v-spacer>
    <div text v-if="token">
      <div text v-if="roleid == 1">
        <router-link to="admin/dashboard" class="routerlink"
          ><v-avatar size="44px">
            <v-img
              :src="
                imgURL + '/' + userProfile.Id + '/avatar/' + userProfile.avatar
              "
            ></v-img> </v-avatar
        ></router-link>
      </div>
      <div text v-else>
        <router-link to="user/home" class="routerlink"
          ><v-avatar size="44px">
            <v-img
              :src="
                imgURL + '/' + userProfile.Id + '/avatar/' + userProfile.avatar
              "
            ></v-img> </v-avatar
        ></router-link>
      </div>
    </div>
    <div text v-else>
      <v-btn to="/auth/login" large color="blue" icon>
        <v-icon>far fa-plus-square</v-icon>
      </v-btn>
    </div>
    <!-- <div text>
      <v-btn to="" color="blue" icon>
        <v-icon dark class="grey--text"> fas fa-bell </v-icon>
      </v-btn>
    </div> -->
    <!-- <v-btn to="/notification" icon color="light-blue">
      <v-icon>fas fa-bell</v-icon>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "HomeAppBar",
  data() {
    return {
      token: null,
      roleid: null,
      imgURL: "https://100milesrealtors.com/routes/storage/images",
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.authenticate.userProfile,
    }),
  },
  mounted() {
    this.getToken();
    this.getUser();
  },
  methods: {
    ...mapActions(["getUser", "logOut"]),
    getToken: function () {
      this.token = localStorage.getItem("token");
      this.roleid = localStorage.getItem("rlid");
    },
  },
};
</script>
<style></style>
