//import api from './Api'
import axios from "axios";
export default {
  getProperty() {
    return axios.get("/propertytypelist");
  },

  postEnquiry(fd) {
    return axios.post(`/postEnquiry`, fd);
  },
  postReview(fd) {
    return axios.post(`/postReview`, fd);
  },
  showProperty(pageNumber) {
    return axios.get("/showProperty?page=" + pageNumber);
  },

  favouriteProperty() {
    return axios.get("/getFavouriteProperty");
  },

  searchProperty(search) {
    // console.log(
    //   `propertyType=${search.propertyType}&city=${search.city}&pfor=${search.pfor}`
    // );
    return axios.get(
      `/searchProperty?propertyType=${search.propertyType}&city=${search.city}&pfor=${search.pfor}`
    );
  },
  propertyDetails(id) {
    return axios.get(`/showPropertyDetails/${id}`);
  },
  propertyByType(id) {
    return axios.get(`/propertyByType/${id}`);
  },

  /* ************ authenticated user property ******* */
  showUserProperty() {
    return axios.get("/showUserProperty");
  },
  postProperty(fd) {
    return axios.post(`/postProperty`, fd);
  },
  updateProperty(id, fd) {
    console.log(id);
    return axios.put(`/updateProperty/${id}`, fd);
  },
  postPropertyPhoto(propertyImage) {
    return axios.post(`/postPropertyPhoto`, propertyImage);
  },
  deleteUserProperty(id) {
    return axios.delete(`/deleteProperty/${id}`);
  },

  /* *************************** admin section  ********************* */
  showAdminProperty() {
    return axios.get("/adminProperty");
  },
  ownerContact(id, contact) {
    return axios.post(`/ownerContact/${id}`, contact);
  },
  adminPropertyDetails(id) {
    return axios.get(`adminPropertyDetails/${id}`);
  },
  upadatePropertyStatus(id, status) {
    return axios.put(`/upadatePropertyStatus/${id}`, status);
  },
  upadateFavouriteStatus(id, status) {
    console.log(id, status);
    return axios.put(`/upadateFavouriteStatus/${id}`, status);
  },
  showAllEnquiry() {
    return axios.get("/allEnquiry");
  },
  upadateEnquiryStatus(id, status) {
    return axios.put(`/upadateReviewStatus/${id}`, status);
  },
  showAllReview() {
    return axios.get("/allReview");
  },
  upadateReviewStatus(id, status) {
    return axios.put(`/upadateReviewStatus/${id}`, status);
  },
  showUserReview() {
    return axios.get("/getReview");
  },
};
