var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-xl",attrs:{"light":"","outlined":"","elevation":"0","height":"275","width":"275","to":{
    name: 'property',
    params: { id: _vm.favProperty.basic_property_id },
  }}},[_c('v-img',{attrs:{"src":_vm.imgURL +
      '/' +
      _vm.favProperty.user_id +
      '/' +
      _vm.favProperty.basic_property_id +
      '/' +
      _vm.favProperty.image_name,"width":"auto","height":"150"}}),_c('v-card-title',[_c('div',{staticClass:"grey--text text--darken-3 text-body-1 font-weight-bold"},[(_vm.favProperty.bedroom)?_c('span',[_vm._v(" "+_vm._s(_vm.favProperty.bedroom)+" BHK ")]):_vm._e(),_vm._v("  "+_vm._s(_vm.favProperty.propertytype.property_name)+" for "+_vm._s(_vm.favProperty.pfor)+" ")])]),_c('v-card-text',{staticClass:"text-caption                \n        bluey-grey--text\n        text--darken-3\n        font-weight-bold"},[_c('v-icon',{attrs:{"left":"","x-small":"","color":"\n        blue-grey accent-4"}},[_vm._v(" fas fa-map-marker-alt")]),_vm._v(" "+_vm._s(_vm.favProperty.locality)+" • "+_vm._s(_vm.favProperty.city)+" "),_c('div',[_c('v-icon',{attrs:{"left":"","small":"","color":"\n      bluey-grey accent-4"}},[_vm._v(" fas fa-rupee-sign ")]),_c('span',[_vm._v(_vm._s(this.editedPrice))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }