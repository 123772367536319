import Authentication from "../../../api/Authentication";
//import axios from 'axios'
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export const registerUser = ({ commit }, credentials) => {
  Authentication.register(credentials)
    .then(function (response) {
      // console.log("response data :", response.data);
      // console.log("response status :", response.status);
      // console.log("response status text :", response.data.message);
      if (response.status == 201) {
        commit("SET_MESSAGE", response.data.message);
        window.location.replace("/auth/login");
      }
    })
    .catch(function (error) {
      if (error.response) {
        // console.log("error data :", error.response.data);
        commit("SET_ERROR_MESSAGE", error.response.data.errors);
        commit("SET_ERROR_EMAIL", error.response.data.errors.email);
        commit("SET_ERROR_CONTACT", error.response.data.errors.contact);
        //console.log('error status :',error.response.status);
      } else if (error.request) {
        // console.log("error request :", error.request);
      } else {
        // console.log("Error message :", error.message);
      }
    });
};

export const logOut = ({ commit }) => {
  //console.log('log out executed')
  Authentication.logout().then((response) => {
    localStorage.removeItem("token");
    localStorage.removeItem("rlid");
    localStorage.removeItem("vrid");
    commit("SET_USER", "null");
    commit("LOG_OUT_MESSAGE", response.data.message);
    window.location.replace("/auth/login");
  });
};

export const getUser = ({ commit }) => {
  //console.log('log out executed')
  Authentication.getUser()
    .then((response) => {
      commit("SET_USER", response.data);
      localStorage.setItem("vrid", response.data.vrid);
    })
    .catch(function (error) {
      if (error.response) {
        //console.log("error data :", error.response.data.message);
        if (error.response.status === 401) {
          this.logOut();
          localStorage.removeItem("token");
          localStorage.removeItem("rlid");
          localStorage.removeItem("vrid");
          //console.log("Token And Role Id Removed..");
          window.location.replace("/auth/login");
        }

        //console.log("error status :", error.response.status);
      } else if (error.request) {
        //console.log("error request :", error.request);
      } else {
        //console.log("Error message :", error.message);
      }
    });
};

export const updateUserName = ({ commit }, credentials) => {
  Authentication.updateUserName(credentials)
    .then((response) => {
      commit("SET_MESSAGE", response.data);
    })
    .catch(function (error) {
      if (error.response) {
        //console.log("error data :", error.response.data.errors);
      } else if (error.request) {
        //console.log("error request :", error.request);
      } else {
        //console.log("Error message :", error.message);
      }
    });
};
export const updateContactNumber = ({ commit }, credentials) => {
  Authentication.updateContactNumber(credentials)
    .then((response) => {
      commit("SET_MESSAGE", response.data);
    })
    .catch(function (error) {
      if (error.response) {
        //console.log("error data :", error.response.data.errors);
      } else if (error.request) {
        //console.log("error request :", error.request);
      } else {
        //console.log("Error message :", error.message);
      }
    });
};
export const changePassword = ({ commit }, credentials) => {
  Authentication.changePassword(credentials)
    .then((response) => {
      commit("SET_MESSAGE", response.data);
    })
    .catch(function (error) {
      if (error.response) {
        //console.log("error data :", error.response.data.errors);
      } else if (error.request) {
        //console.log("error request :", error.request);
      } else {
        // console.log("Error message :", error.message);
      }
    });
};
export const updateProfilePhoto = ({ commit }, credentials) => {
  Authentication.updateProfilePhoto(credentials)
    .then((response) => {
      commit("SET_MESSAGE", response.data);
    })
    .catch(function (error) {
      if (error.response) {
        //console.log("error data :", error.response.data.errors);
      } else if (error.request) {
        //console.log("error request :", error.request);
      } else {
        //console.log("Error message :", error.message);
      }
    });
};
/* *********************************** Admin Section **************************** */

export const getAllUser = ({ commit }) => {
  //console.log('log out executed')
  Authentication.allUsers().then((response) => {
    commit("SET_ALL_USER", response.data);
  });
};
