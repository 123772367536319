import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
//import usedevice from "./plugins/useDevice";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.config.productionTip = false;

require("@/store/subcriber");
// (axios.defaults.baseURL = "http://127.0.0.1:8000/api/"),
(axios.defaults.baseURL = "https://www.100milesrealtors.com/routes/api"),
  (axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token"));
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
