import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import state from "./state";
import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";

import amenity from "./modules/amenity";
import city from "./modules/city";
import property from "./modules/property";
import authenticate from "./modules/authenticate";

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,

  modules: {
    city,
    amenity,
    property,
    authenticate,
  },
});
