export const SET_BASIC_AMENITY = (state, basic) => {
  state.basicamenities = basic;
};
export const SET_SAFETY_AMENITY = (state, safety) => {
  state.safetyamenities = safety;
};
export const SET_PARKING_AMENITY = (state, parking) => {
  state.parkingamenities = parking;
};
export const SET_WATER_AMENITY = (state, water) => {
  state.wateramenities = water;
};
export const SET_FLOORING_AMENITY = (state, flooring) => {
  state.flooringamenities = flooring;
};
export const SET_POP_AMENITY = (state, pop) => {
  state.popamenities = pop;
};
export const SET_KITCHEN_AMENITY = (state, kitchen) => {
  state.kitchenamenities = kitchen;
};
export const SET_FURNISHED_AMENITY = (state, furnished) => {
  state.furnishedamenities = furnished;
};
export const SET_MAINTENANCE_AMENITY = (state, maintenance) => {
  state.maintenanceamenities = maintenance;
};
export const SET_OTHER_AMENITY = (state, other) => {
  state.otheramenities = other;
};
