var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-3 my-2 "},[_c('v-card',{staticClass:"rounded-lg py-2 px-2",attrs:{"elevation":"0","width":"380","to":{
      name: 'property',
      params: { id: _vm.property.basic_property_id },
    }}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"5"}},[_c('v-img',{staticClass:"rounded-lg",attrs:{"src":_vm.imgURL +
            '/' +
            _vm.property.user_id +
            '/' +
            _vm.property.basic_property_id +
            '/' +
            _vm.property.image_name,"height":"100px"}})],1),_c('v-col',{staticClass:"d-flex flex-column mt-2",attrs:{"cols":"7"}},[_c('div',{staticClass:"text--subtitle-2 mt-1 light-blue--text font-weight-bold"},[(_vm.property.bedroom)?_c('span',[_vm._v(" "+_vm._s(_vm.property.bedroom)+" BHK ")]):_vm._e(),_vm._v("  "+_vm._s(_vm.property.propertytype.property_name)+" for "+_vm._s(_vm.property.pfor)+" ")]),_c('span',{staticClass:"caption blue-grey--text"},[_vm._v(" "+_vm._s(_vm.property.locality)+" ")]),_c('span',{staticClass:"caption blue-grey--text"},[_c('v-icon',{attrs:{"left":"","x-small":"","color":"\n              blue-grey accent-4"}},[_vm._v(" fas fa-map-marker-alt")]),_c('span',[_vm._v(" "+_vm._s(_vm.property.city)+" ")]),_vm._v(" . "),_c('v-icon',{attrs:{"left":"","x-small":"","color":"\n               blue-grey accent-3"}},[_vm._v(" fas fa-rupee-sign ")]),_c('span',[_vm._v(_vm._s(this.editedPrice))])],1)])],1)],1),_c('v-divider',{staticClass:"my-2"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }