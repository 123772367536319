export default {
  propertylist: [],
  error: null,
  property_message: null,
  allProperty: {},
  allSearchProperty: [],
  propertyByType: [],
  property_type: [],
  basicDetails: [],
  propertyPhoto: [],
  amenityDetails: [],
  allEnquiry: [],
  allReview: [],
  getUserReview: [],
  favouriteProperty: [],
  /* ******************* Authenticated User Property States ********************* */
  userProperty: [],
  /* ******************* Admin Property States ********************* */
  allAdminProperty: [],
};
