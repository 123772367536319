<template>
  <div>
    <template>
      <div class="text-center">
        <v-bottom-sheet v-model="sheet" inset>
          <template v-slot:activator="{ on, attrs }">
            <v-card outlined class="rounded-lg"
            v-bind="attrs"
              v-on="on"
            >
            <v-row
              class="d-flex flex-row justify-space-around my-1 mx-2"
              
            >
           

           
              <v-col cols="10" class="">
                <div class="text-left ml-2">Search your property</div> 
              </v-col>
              <v-col cols="2" class="">
                <v-icon class="blue-grey--text"> fas fa-search </v-icon>
               
              </v-col>
            </v-row>
          </v-card>
            <!-- <v-btn
              text
              outlined
              x-large
              block
              color="white"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left dark class="grey--text"> fas fa-search </v-icon>
              <div class="caption grey--text">search your property here...</div>
            </v-btn> -->
          </template>
          <!-- property searching form -->
          <v-sheet class="pa-2 rounded-t-lg" height="auto">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              top
              color="blue accent-4"
            ></v-progress-linear>
            <div class="mt-2 text-right">
              <v-btn icon color="grey lighten-1" @click="sheet = !sheet">
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </div>
            <div class="mt-2 text-left">
              <div class="subtitle-1 font-weight-bold">
                SEARCH YOUR PROPERTY HERE
              </div>
            </div>
            <v-divider></v-divider>
            <div class="my-3">
              <v-snackbar v-model="snackbar" color="error" rounded="pill" top>
                {{ text }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    icon
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </template>
              </v-snackbar>
              <v-form
                @submit.prevent="searchProperty"
                ref="form"
                enctype="multipart/form-data"
                v-model="valid"
                lazy-validation
              >
                <div class="d-flex flex-column align-center">
                  <v-radio-group v-model="search.pfor" row :rules="pforRules">
                    <v-radio label="Rent" value="rent"></v-radio>
                    <v-radio label="Buy" value="sale"></v-radio>
                  </v-radio-group>

                  <v-select
                    :items="states"
                    item-text="state_name"
                    item-value="id"
                    :rules="stateRules"
                    label="Select State"
                    v-model="state"                    
                    outlined                    
                  />

                  <v-select
                  v-if="state != null"
                    :items="districts"
                    v-model="search.city"
                    item-text="district_name"
                    item-value="id"
                    label="Select District"
                    :rules="districtRules"
                    @click="getDistrictList"
                   outlined
                  />

                  <v-select
                    v-model="search.propertyType"
                    :items="propertylist"
                    item-text="property_name"
                    item-value="id"
                    label="Select Property"
                    :rules="propertyRules"
                    outlined
                  />
                  <v-btn
                    type="submit"                                                            
                    color="primary"
                    block
                    :disabled="!valid"
                    @click="validate"
                  >
                    <v-icon left> mdi-magnify </v-icon>
                    Search
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-sheet>
        </v-bottom-sheet>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Property from "../api/Property";
import AddPropertyMixin from "../mixins/AddPropertyMixin";
export default {
  name: "SearchProperty",
  mixins: [AddPropertyMixin],
  data() {
    return {
      sheet: false,
      loading: false,
      snackbar: false,
      text: "",
      state: "",
      search: {
        city: "",
        pfor: "",
        propertyType: "",
      },

      mini: true,
      valid: true,
    };
  },
  computed: {
    ...mapState({
      propertylist: (state) => state.property.propertylist,
      states: (state) => state.city.states,
      districts: (state) => state.city.districts,
      cities: (state) => state.city.cities,
    }),
  },
  mounted() {
    this.getProperty();
    this.getState();
  },
  methods: {
    ...mapActions(["getCity","getDistrict", "getState", "getProperty"]),
    validate() {
      this.$refs.form.validate();
    },
    getDistrictList() {
      console.log(this.state);
      this.getDistrict({
        districtId: this.state,
      });
    },
    getCityList() {
      console.log(this.state);
      this.getCity({
        cityId: this.state,
      });
    },
    searchProperty() {
      (this.loading = true),
        Property.searchProperty(this.search)
          .then((response) => {
            //console.log('response got....',response.data);
            if (response.data.status_code == 200) {
              // console.log(
              //   "201 response got....",
              //   response.data.search_property
              // );
              (this.loading = false),
                this.$store.commit(
                  "SET_SEARCH_PROPERTY",
                  response.data.search_property
                );
              this.$router.replace("searchproperty");
            }
            if (response.data.status_code == 204) {
              // console.log("204 response got....", response.data);
              (this.loading = false),
                (this.snackbar = true),
                (this.text = response.data.message);
            }
          })
          .catch(function (error) {
            console.log("Error message :", error.message);
          });
    },
  },
};
</script>
