<template>
  <v-row class="white my-2">
    <v-col class="ml-3 grey--text text--darken-3 font-weight-bold text-subtitle-1 ">
      Property by category
    </v-col>
    <v-col class="d-flex justify-center align-center white ">
    <v-card
      outlined
      class="d-xl-none mb-3 px-2 py-3 rounded-xl"
      elevation="0"
      max-width="380"
    >
      <v-slide-group class="d-flex justify-center center-active">
        <v-slide-item v-for="list in propertyType" :key="list.id" v-slot="{toggle }">
          <router-link
            class="text-decortion"
            :to="{
              name: 'propertyByType',
              params: { id: list.prop_id },
            }"
            @click="toggle"
          >
            <div class="mr-5 d-flex flex-column align-center mx-1">
              <v-avatar size="80" class="elevation-2" rounded="xl">
                <v-img
                  alt="Property type"
                  :src="require(`../assets/p_type/${list.pimg}`)"
                />
              </v-avatar>
              <div
                class="
                  text-caption
                  mt-3
                  grey--text
                  text--darken-3
                  font-weight-bold
                "
              >
                {{ list.title }}
              </div>
            </div>
          </router-link>
        </v-slide-item>
      </v-slide-group>
    </v-card>
  </v-col>
  </v-row>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      propertyType: [
        {
          pimg: "appartment.png",
          title: "Apartment",
          prop_id: "1",
        },
        { pimg: "bungalow.jpeg", title: "Bungalow", prop_id: "2" },
        { pimg: "row_house.jpeg", title: "Row House", prop_id: "3" },
        { pimg: "vila.jpg", title: "Villa", prop_id: "4" },
        { pimg: "farm_house.jpg", title: "Farm House", prop_id: "8" },
        {
          pimg: "office_space.jpg",
          title: "Office Space",
          prop_id: "10",
        },
        {
          pimg: "plot.png",
          title: "Land/Plot",
          prop_id: "17",
        },
        { pimg: "shops.png", title: "Shops", prop_id: "11" },
      ],
    };
  },
  computed: {
    ...mapState({
      propertylist: (state) => state.property.propertylist,
    }),
  },
  mounted() {
    this.getProperty();
  },
  methods: {
    ...mapActions(["getProperty"]),
  },
};
</script>
<style scoped>
.card-bg {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgb(255, 255, 255, 0) 100%
  );
}
.text-decortion {
  text-decoration: none;
}
</style>
