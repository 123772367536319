<template>
  <v-app>
    <v-main>
      <div v-if="orientation == 0" class="main-bg-color">
        <router-view />
      </div>
      <div
        v-else
        class="d-flex justify-center flex-column align-center pa-4 custom"
      >
        <v-icon x-large color="yellow">fas fa-undo</v-icon>
        <h2>Please rotate your device in portrait mode.</h2>
        <p>
          We dont support landscape mode. for best result rotate device in
          portrait mode.
        </p>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      orientation: "",
      //scWt: "",
      //isMobile: false,
    };
  },
  mounted() {
    //this.redirectUrl();
    window.addEventListener("resize", this.handleOrientationChange);
    //window.addEventListener("resize", this.redirectUrl);
  },
  methods: {
    handleOrientationChange() {
      this.orientation = window.orientation;
      //console.log("Orientation is :", this.orientation);
    },
    // redirectUrl() {
    //   this.scWt = screen.width;
    //   if (screen.width > 1024) {
    //     window.location.replace("https://100milesrealtors.com/");
    //   }
    // },
  },
};
</script>

<style>
.main-bg-color{
  background: #f5f5f5;
}
.routerlink {
  text-decoration: none;
}
.custom {
  height: 100vh;
  width: 100vw;
  background: #0288d1;
  color: #fff;
}
.title-bg {
  background-image: linear-gradient(235deg, #c2e4fe 0%, #0190ff 100%);
}
</style>
