import City from "../../../api/City";

export const getState = ({ commit }) => {
  City.getState().then((response) => {
    //console.log(response.data);
    commit("SET_STATES", response.data);
  });
};
export const getDistrict = ({ commit },{ districtId }) => {
  City.getDistrict(districtId).then((response) => {
    console.log("SET_DISTRICT",response.data);
    commit("SET_DISTRICT", response.data);
  });
};

export const getCity = ({ commit }, { cityId }) => {
  City.getcity(cityId).then((response) => {
    commit("SET_CITIES", response.data);
  });
};
