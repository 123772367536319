export const SET_STATES = (state, states) => {
  state.states = states;
};
export const SET_DISTRICT = (state,district) => {
state.districts=district;
}

export const SET_CITIES = (state, cities) => {
  state.cities = cities;
};
