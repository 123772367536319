<template>
    <div class="mx-3 my-2 ">
      <v-card
        class="rounded-lg py-2 px-2"
        elevation="0"        
        width="380"
        :to="{
          name: 'property',
          params: { id: property.basic_property_id },
        }"
      >
      <v-row >
          <v-col cols="5" class="d-flex justify-center align-center">
            <v-img
              :src="
                imgURL +
                '/' +
                property.user_id +
                '/' +
                property.basic_property_id +
                '/' +
                property.image_name
              "
              height="100px"
              class="rounded-lg"
            />
          </v-col>
          <v-col cols="7" class="d-flex flex-column mt-2">
            <div class="text--subtitle-2 mt-1 light-blue--text font-weight-bold">
              <span v-if="property.bedroom"> {{ property.bedroom }} BHK </span>
              &nbsp;{{ property.propertytype.property_name }} for
              {{ property.pfor }}
            </div>
            <span class="caption blue-grey--text">
              {{ property.locality }}
            </span>
            <span class="caption blue-grey--text">
              <v-icon
                left
                x-small
                color="
                  blue-grey accent-4"
              >
                fas fa-map-marker-alt</v-icon
              ><span>
                {{ property.city }}
              </span>
              .
              <v-icon
                left
                x-small
                color="
                   blue-grey accent-3"
              >
                fas fa-rupee-sign
              </v-icon>
              <span>{{ this.editedPrice }}</span>
            </span>
          </v-col>
        </v-row>

        <!-- <v-row class="px-2">
          <v-col cols="4">
            <v-icon
              left
              x-small
              color="
          blue-grey accent-4"
            >
              fas fa-map-marker-alt
            </v-icon>
            <span class="caption">
              {{ property.city }}
            </span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-bottom-sheet v-model="sheet" inset>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-share-variant-outline</v-icon>
                </v-btn>
              </template>
              <v-sheet class="pa-2 rounded-lg text-center" height="auto">
                <v-row class="my-1">
                  <v-col cols="10">
                    <v-text-field
                      v-on:focus="$event.target.select()"
                      ref="myinput"
                      readonly
                      :value="copyUrl"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-btn icon @click="copyLink">
                      <v-icon>far fa-copy</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="my-2">
                  <v-col
                    cols="3"
                    v-for="(share, index) in shareLinks"
                    :key="index"
                  >
                    <v-btn icon :href="share.link">
                      <v-icon>{{ share.icon }}</v-icon>
                    </v-btn></v-col
                  >
                </v-row>
              </v-sheet>
            </v-bottom-sheet>
          </v-col>
        </v-row> -->
      </v-card>      
      <v-divider class="my-2"></v-divider>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["property"],
  data() {
    // let baseUrl =
    //   window.location.origin +
    //   "/m/ShowPropertyDetails/" +
    //   this.property.basic_property_id;
    return {
      // sheet: false,
      // copyUrl: baseUrl,
      imgURL: "https://100milesrealtors.com/routes/storage/images",
      show: false,
      editedPrice: "",
      stringVal: "",
      amt: "",
      // shareLinks: [
      //   {
      //     title: "whatsapp",
      //     icon: "fab fa-whatsapp",
      //     link: "https://wa.me/?text=" + baseUrl,
      //   },
      //   {
      //     title: "facebook",
      //     icon: "fab fa-facebook",
      //     link: "https://www.facebook.com/sharer/sharer.php?u=" + baseUrl,
      //   },
      //   {
      //     title: "twitter",
      //     icon: "fab fa-twitter",
      //     link: "https://twitter.com/intent/tweet?text=" + baseUrl,
      //   },
      //   {
      //     title: "Linked In",
      //     icon: "fab fa-linkedin",
      //     link:
      //       "https://www.linkedin.com/shareArticle?mini=true&url=" + baseUrl,
      //   },
      // ],
    };
  },
  mounted() {
    this.printValue();
  },
  methods: {
    ...mapActions(["viewPropertyDetails"]),
    // copyLink() {
    //   this.$refs.myinput.focus();
    //   document.execCommand("copy");
    // },
    printValue() {
      let amt = this.property.price;
      let count = amt.toString().length;
      //console.log("count is :", count);
      switch (count) {
        case 6:
          this.stringVal = amt.toString().substr(0, 3);
          this.editedPrice =
            this.stringVal.toString().substr(0, 1) +
            "." +
            this.stringVal
              .toString()
              .substr(1, this.stringVal.toString().length) +
            " Lac";
          break;
        case 7:
          this.stringVal = amt.toString().substr(0, 4);
          this.editedPrice =
            this.stringVal.toString().substr(0, 2) +
            "." +
            this.stringVal
              .toString()
              .substr(2, this.stringVal.toString().length) +
            " Lac";
          break;
        case 8:
          this.stringVal = amt.toString().substr(0, 3);
          this.editedPrice =
            this.stringVal.toString().substr(0, 1) +
            "." +
            this.stringVal
              .toString()
              .substr(1, this.stringVal.toString().length) +
            " Crore";
          break;
        case 9:
          this.stringVal = amt.toString().substr(0, 4);
          this.editedPrice =
            this.stringVal.toString().substr(0, 2) +
            "." +
            this.stringVal
              .toString()
              .substr(2, this.stringVal.toString().length) +
            " crore";
          break;
      }
    },
  },
};
</script>
<style scoped>
router-link {
  text-decoration: none;
}
.title-color {
  color: #212121;
}
</style>
