//import api from './Api'
import axios from "axios";
export default {
  getState() {
    return axios.get("/state");
  },
  getDistrict(id) {
    return axios.get(`/getDistrict/${id}`);
  },
  getcity(id) {
    return axios.get(`/getcity/${id}`);
  },
};
