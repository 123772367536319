export default {
  userProfile: [],
  allUsers: [],
  token: null,
  email: "",
  message: null,
  error_message: [],
  error_message_contact: null,
  error_message_email: null,
  logout_message: null,
};
