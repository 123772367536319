import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,    
  },
  {
    path: "/searchproperty",
    name: "searchproperty",
    component: () => import("../views/ShowSearchProperty.vue"),
  },
  {
    path: "/propertybytype/:id",
    name: "propertyByType",
    props: true,
    component: () => import("../views/PropertyByType.vue"),
  },

  /* ********************************** OTHER PAGES ******************** */
  {
    path: "/hmp",
    component: () => import("../views/OtherPages.vue"),
    children: [
      {
        path: "/ShowPropertyDetails/:id",
        name: "property",
        props: true,
        component: () => import("../views/ShowPropertyDetails.vue"),       
      },
      {
        path: "/notification",
        name: "notification",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
      {
        path: "review",
        name: "owner-review",
        component: () => import("../views/Review.vue"),
      },

      {
        path: "about",
        name: "about-us",
        component: () => import("../views/About.vue"),
      },
      {
        path: "finance",
        name: "finance",
        component: () => import("../views/Finance.vue"),
      },
      {
        path: "PrivacyPolicy",
        name: "privacy-policy",
        component: () => import("../views/PrivacyPolicy.vue"),
      },
      {
        path: "TermsAndConditions",
        name: "terms-and-conditions",
        component: () => import("../views/TermsAndConditions.vue"),
      },
      {
        path: "services",
        name: "services",
        component: () => import("../views/Services.vue"),
      },
      {
        path: "download-app",
        name: "app-download",
        component: () => import("../views/AppDownload.vue"),
      },
      {
        path: "contactus",
        name: "contact-us",
        component: () => import("../views/ContactUs.vue"),
      },
    ],
  },

  /* ********************************** USER SECTION ******************** */
  {
    path: "/user",
    component: () => import("../views/user/User.vue"),
    children: [
      {
        path: "home",
        name: "userhome",
        component: () => import("../views/user/UserHome.vue"),
        beforeEnter(to, from, next) {
          if (vrid() == 1) {
            next();
          } else {
            next({ path: "/user/verify-account" });
          }
        },
      },
      {
        path: "verify-email",
        name: "verify-email",
        component: () => import("../views/auth/VerifyEmail.vue"),
        //props:true,
        //component:ShowPropertyDetails
      },
      {
        path: "verify-account",
        name: "verify-account",
        component: () => import("../views/auth/VerifyAccount.vue"),
      },
      {
        path: "userdashboard",
        component: () => import("../views/user/UserDashboard.vue"),
        beforeEnter(to, from, next) {
          if (vrid() == 1) {
            //console.log("before route enter user dashboard executed");
            next();
          } else {
            next({ path: "/user/verify-account" });
          }
        },
      },
      {
        path: "addproperty",
        //component: AddProperty,
        component: () => import("../views/user/AddProperty.vue"),
        beforeEnter(to, from, next) {
          if (vrid() == 1) {
            next();
          } else {
            next({ path: "/user/verify-account" });
          }
        },
      },
      {
        path: "edit-property-details/:id",
        name: "editUserProperty",
        props: true,
        component: () => import("../views/user/EditProperty.vue"),
      },
      {
        path: "viewpropertydetails/:id",
        name: "userProperty",
        component: () => import("../views/admin/ViewPropertyDetails.vue"),
        props: true,
        //component:ShowPropertyDetails
      },
      {
        path: "profilesetting",
        name: "userprofilesetting",
        //component: ProfileSetting,
        component: () => import("../components/ProfileSetting"),
      },
    ],
    meta: { authOnly: true },
    beforeEnter(to, from, next) {
      if (role() == 2 || role() == 3 || role() == 4) {
        next();
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("rlid");
        next({ path: "/auth/login" });
      }
    },
  },

  {
    path: "/admin",
    component: () => import("../views/admin/Admin.vue"),
    children: [
      {
        path: "dashboard",
        name: "admindashboard",
        component: () => import("../views/admin/AdminDashboard.vue"),
      },
      {
        path: "home",
        //component: AdminHome,
        component: () => import("../views/admin/AdminHome.vue"),
      },
      {
        path: "addproperty",
        //component: AddProperty,
        component: () => import("../views/user/AddProperty.vue"),
      },
      {
        path: "alluser",
        //component: AllUsers,
        component: () => import("../views/admin/AllUsers.vue"),
      },
      {
        path: "allproperty",
        //component: AllProperty,
        component: () => import("../views/admin/AllProperties.vue"),
      },
      {
        path: "edit-property-details/:id",
        name: "editAdminProperty",
        props: true,
        component: () => import("../views/user/EditProperty.vue"),
      },
      {
        path: "viewpropertydetails/:id",
        name: "adminProperty",
        component: () => import("../views/admin/ViewPropertyDetails.vue"),
        props: true,
        //component:ViewPropertyDetails,
      },
      {
        path: "enquiries",
        component: () => import("../views/admin/Enquiry.vue"),
      },
      {
        path: "reviews",
        component: () => import("../views/admin/Review.vue"),
      },
      {
        path: "profilesetting",
        name: "adminprofilesetting",
        //component: ProfileSetting,
        component: () => import("../components/ProfileSetting"),
      },
    ],
    meta: { authOnly: true },
    beforeEnter(to, from, next) {
      if (role() == 1) {
        next();
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("rlid");
        next({ path: "/auth/login" });
      }
    },
  },

  /* ************************************ AUTHENTICATION ******************************* */
  {
    path: "/auth",
    component: () => import("../views/auth/Auth.vue"),
    meta: { guestOnly: true },
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("../views/auth/Login.vue"),
      },
      {
        path: "register",
        component: () => import("../views/auth/Register.vue"),
      },
      {
        path: "forgotpassword",
        component: () => import("../views/auth/ForgotPassword.vue"),
      },
      {
        path: "resetpassword",
        component: () => import("../views/auth/ResetPassword.vue"),
      },
    ],
  },
  {
    path: "*",
    component: () => import("../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
function isLoggedIn() {
  return localStorage.getItem("token");
}

function role() {
  return localStorage.getItem("rlid");
}

function vrid() {
  return localStorage.getItem("vrid");
}
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      console.log("!isLoggedIn", isLoggedIn());
      next({
        path: "/auth/login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      if (role() == 1) {
        next({
          path: "/admin/dashboard",
        });
      } else {
        next({
          path: "/user/home",
        });
      }
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
