import axios from "axios";
const authenticate = "auth";

export default {
  register(userData) {
    return axios.post(`/${authenticate}/register`, userData);
  },
  login(credentials) {
    return axios.post(`/${authenticate}/login`, credentials);
  },
  forgotPassword(email) {
    return axios.post(`/${authenticate}/forgotpassword?email=${email}`);
  },
  resetPassword(credentials) {
    //console.log(email);
    return axios.post(`/${authenticate}/resetpassword`, credentials);
  },
  getUser() {
    return axios.get("/profile");
  },
  resendEmail() {
    return axios.post(`/resendEmail`);
  },
  verifyUser(credentials) {
    return axios.post(`/verifyUser`, credentials);
  },
  changePassword(id, credentials) {
    return axios.put(`/changePassword/${id}`, credentials);
  },
  updateContactNumber(id, number) {
    return axios.put(`/updateContactNumber/${id}`, number);
  },
  updateUserName(id, name) {
    return axios.put(`/updateUserName/${id}`, name);
  },
  /* updateProfilePhoto(id,photo){
        console.log(id,photo);
        return api.put(`/updateProfilePhoto/${id}`,photo);
    },*/
  uploadImage(id, photo) {
    return axios.post(`/uploadImage/${id}`, photo);
  },
  logout() {
    return axios.post("/logout");
  },

  /* ************************ Admin Section ************************* */
  allUsers() {
    return axios.get("/allUsers");
  },
};
