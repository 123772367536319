<template>
      <v-row class="my-2 white d-flex justify-center align-center py-2">
        <v-col class="ml-4 grey--text text--darken-3 font-weight-bold text-subtitle-1">
            All Reviews
          </v-col>
        <v-card
        class="d-xl-none mb-3 px-2 py-1 rounded-lg"
            elevation="0"
            max-height="400"
            width="380"
        >
          <v-slide-group class="d-flex justify-center center-active" mobile-breakpoint="576">
            <v-slide-item v-for="review in getUserReview" :key="review.id" v-slot="{toggle }">
              <v-card
                width="250"
                class="
                  d-flex
                  flex-column
                  align-center
                  mx-2
                  my-4
                  justify-center
                  pa-4
                "
                outlined
                @click="toggle"
              >
              <div class="review-section text-center my-2 text-subtitle-2">
                  " {{ review.review }} "
                </div>

                <div>
                  <v-rating
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    half-increments
                    :value="review.rating"
                    readonly
                    size="14"
                  ></v-rating>
                </div> 
                <div
                  class="
                    text-body-2
                    mt-2
                    grey--text
                    text--darken-4
                    font-weight-bold
                  "
                >
                  {{ review.name }}
                </div>
                <div class="text-caption mt-1">
                  {{ review.city }}
                </div>                               
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-card>
      </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      // loading: false,
    };
  },

  computed: {
    ...mapState({
      getUserReview: (state) => state.property.getUserReview,
    }),
  },
  created() {
    this.showUserReview();
  },
  methods: {
    ...mapActions(["showUserReview"]),
  },
};
</script>

<style scoped>
.review-section {
  height: "150px";
}
</style>
